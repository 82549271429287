import { Grid, ThemeProvider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import theme from "../theme";
import axios from "axios";
import GoogleMapReact from "google-map-react";
import "../css/all.min.css";

const useStyles = makeStyles(theme => ({
  submitButton: {
    marginTop: 20,
    cursor: "pointer",

    transition:
      "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    padding: "10px 20px 10px 20px",
    border: "1px solid #0b9444",
    textDecoration: "none",
    color: "#0b9444",
    "&:hover": {
      border: "1px solid white",
      backgroundColor: "#c49a6c",
      color: "white",
    },
  },
  contactInputGroup: {
    display: "flex",
    flexDirection: "column",
  },
  contactInput: {
    width: "calc(100% - 40px)",
    padding: "10px 20px 10px 20px",
    border: "1px solid black",
    textDecoration: "none",
  },
  validationError: {
    color: "red",
    fontSize: "0.75em",
  },
}));

const ContactPage = () => {
  const classes = useStyles();

  const [contactInfo, setContactInfo] = useState({
    name: "",
    email: "",
    phonenumber: "",
    message: "",
  });

  const [error, setError] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const [contactInfoRequired, setContactInfoRequired] = useState({
    name: false,
    email: false,
    phonenumber: false,
    message: false,
  });

  const hasLength = (e: string) => {
    return typeof e === "string" && e.length > 0;
  };

  const handleValidation = () => {
    setContactInfoRequired({
      ...contactInfoRequired,
      name: !hasLength(contactInfo.name),
      email: !hasLength(contactInfo.email),
      phonenumber: !hasLength(contactInfo.phonenumber),
      message: !hasLength(contactInfo.message),
    });

    return (
      !contactInfoRequired.name &&
      !contactInfoRequired.email &&
      !contactInfoRequired.phonenumber &&
      !contactInfoRequired.message
    );
  };

  const handleSubmit = (event: any) => {
    handleValidation();
    event.preventDefault();

    let formData = new FormData(); //formdata object

    formData.append("name", contactInfo.name);
    formData.append("email", contactInfo.email);
    formData.append("phonenumber", contactInfo.phonenumber);
    formData.append("message", contactInfo.message);
    formData.append("subject", "Nieuw bericht van " + contactInfo.name);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    setSending(true);
    axios
      .post(
        "https://mail.rankxen.be/mail/template/apHyIUoLhaTYbDnY",
        formData,
        config
      )
      .then(response => {
        console.log(response);
        setError(false);
        setSending(false);
        setContactInfo({
          name: "",
          email: "",
          phonenumber: "",
          message: "",
        });
        setSent(true);
      })
      .catch(error => {
        console.log(error);
        setError(true);
        setSending(false);
      });
  };

  const handleChange = (event: any) => {
    setContactInfo({ ...contactInfo, [event.target.name]: event.target.value });
  };

  const mapProps = {
    center: {
      lat: 50.747748826430495,
      lng: 3.6030496187554184,
    },
    zoom: 16,
  };

  const handleApiLoaded = (map: any, maps: any) => {
    const myMarkerLatLong = new maps.LatLng(
      50.747748826430495,
      3.6030496187554184
    );
    const marker = new maps.Marker({
      position: myMarkerLatLong,
      label: "Concilium",
      title: "Concilium bemiddeling\nKantoor 33",
    });

    marker.setMap(map);
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Layout>
          <SEO title="Contact" />
          <Typography
            data-aos="fade-right"
            variant="h5"
            style={{ marginBottom: 30, marginTop: 20 }}
          >
            Locatie - Ronse
          </Typography>

          {/*MAP*/}
          <Grid container spacing={3} style={{ marginBottom: 30 }}>
            <Grid item xs={12} sm={6}>
              <div data-aos="fade-right" style={{ width: "100%", height: 300 }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyAgSC4IXrLTO70cJUMD4mB5Kbigq9XMpu4",
                  }}
                  defaultCenter={mapProps.center}
                  defaultZoom={mapProps.zoom}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({ map, maps }: any) =>
                    handleApiLoaded(map, maps)
                  }
                ></GoogleMapReact>
              </div>
            </Grid>

            <Grid
              data-aos="fade-right"
              item
              xs={12}
              sm={6}
              container
              direction="column"
            >
              <Typography variant="h5">
                Advocatenkantoor - Kantoor 33
              </Typography>
              <Typography variant="subtitle1">
                <div>
                  Grote markt 33
                </div>
                <div>9600, Ronse</div>
                <div>België</div>
                <div>
                  <a
                    style={{ all: "unset", paddingRight: 4, cursor: "pointer" }}
                    href="tel:+32476387680"
                  >
                    <i style={{ paddingRight: 4 }} className="fa fa-phone"></i>
                    +32 (0)476.38.76.80
                  </a>
                </div>
                <div>
                  <a
                    style={{ all: "unset", paddingRight: 4, cursor: "pointer" }}
                    href="mailto:Maarten@concilium-bemiddeling.be"
                  >
                    <i
                      style={{ paddingRight: 4 }}
                      className="fa fa-envelope"
                    ></i>
                    Maarten@concilium-bemiddeling.be
                  </a>
                </div>
              </Typography>
            </Grid>

            <Grid></Grid>
          </Grid>
          {/*CONTACT FORM*/}
          <Typography
            data-aos="fade-left"
            data-aos-delay="150"
            variant="h5"
            style={{ marginBottom: 30 }}
          >
            Stel een vraag of maak een afspraak
          </Typography>
          <form
            data-aos="fade-left"
            data-aos-delay="150"
            onSubmit={handleSubmit}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={classes.contactInputGroup}>
                  <label>
                    <Typography variant="subtitle1">Naam*</Typography>
                  </label>
                  <input
                    disabled={sending}
                    className={classes.contactInput}
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={contactInfo.name}
                  />
                  {contactInfoRequired["name"] && (
                    <span className={classes.validationError}>
                      Dit veld is verplicht
                    </span>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={classes.contactInputGroup}>
                  <label>
                    <Typography variant="subtitle1">Email*</Typography>
                  </label>
                  <input
                    disabled={sending}
                    className={classes.contactInput}
                    name="email"
                    type="email"
                    onChange={handleChange}
                    value={contactInfo.email}
                  />
                </div>
                {contactInfoRequired["email"] && (
                  <span className={classes.validationError}>
                    Dit veld is verplicht
                  </span>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={classes.contactInputGroup}>
                  <label>
                    <Typography variant="subtitle1">Tel*</Typography>
                  </label>
                  <input
                    disabled={sending}
                    className={classes.contactInput}
                    type="text"
                    name="phonenumber"
                    onChange={handleChange}
                    value={contactInfo.phonenumber}
                  />
                </div>
                {contactInfoRequired["phonenumber"] && (
                  <span className={classes.validationError}>
                    Dit veld is verplicht
                  </span>
                )}
              </Grid>
              <Grid item xs={12}>
                <div className={classes.contactInputGroup}>
                  <label>
                    <Typography variant="subtitle1">Bericht*</Typography>
                  </label>
                  <textarea
                    disabled={sending}
                    style={{ resize: "none" }}
                    className={classes.contactInput}
                    rows={4}
                    name="message"
                    onChange={handleChange}
                    value={contactInfo.message}
                  ></textarea>
                </div>
                {contactInfoRequired["message"] && (
                  <span className={classes.validationError}>
                    Dit veld is verplicht
                  </span>
                )}
              </Grid>
            </Grid>
            <button
              className={classes.submitButton}
              style={{ marginTop: 20 }}
              type="submit"
            >
              {!sending ? "Versturen" : "Verzenden..."}
            </button>
            {sent && (
              <span style={{color: "green"}}>
                Uw bericht is succesvol verstuurd.
              </span>
            )}
            {error && (
              <span className={classes.validationError}>
                Er ging iets verkeerd bij het versturen van de afspraak.
              </span>
            )}
          </form>
        </Layout>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default ContactPage;
